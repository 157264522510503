import React from "react"
import styled from "@emotion/styled"
import Markdown from "react-markdown/with-html"
import Container from "../components/container"
import CodeBlock from "../components/markdown/codeBlock"
import Heading from "../components/markdown/heading"
import RestrictedPage from "../components/restrictedPage"

const StyledContainer = styled(Container)`
  h1 {
    font-size: 44px;
    line-height: 50px;
  }

  h2 {
    font-size: 30px;
    line-height: 36px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
  }
`

const StyledMarkdown = styled(Markdown)`
  img {
    max-width: 100%;
  }

  blockquote {
    font-style: italic;
    background: #eee;
    padding: 1em 40px;
    margin: 0;
  }

  pre {
    background: #222;
    color: #eee;
    padding: 10px;
    margin: 50px auto;
  }

  pre code {
    background: none;
    padding: 0;
    border-radius: 0;
  }

  code {
    background: rgba(238, 238, 170, 0.4);
    padding: 3px 6px;
    border-radius: 4px;
  }
`;

export default function Template({ pageContext }) {
  const { pageTitle, pageContent } = pageContext
  return (
    <RestrictedPage>
      <StyledContainer>
        <h1>{pageTitle}</h1>
        <StyledMarkdown
          className="blog-post-content"
          source={pageContent}
          escapeHtml={false}
          renderers={{
            code: CodeBlock,
            heading: Heading,
          }}
        />
      </StyledContainer>
    </RestrictedPage>
  )
}
