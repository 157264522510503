import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import createElement from 'react-syntax-highlighter/dist/esm/create-element';
import { atomOneDarkReasonable } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const renderer = (added, updated) => ({ rows, stylesheet, useInlineStyles }) => rows.map((row, i) => {
  const style = {};
  if (added.includes(i)) {
    style.display = 'block';
    style.background = 'rgba(104, 247, 142, 0.2)';
  } else if (updated.includes(i)) {
    style.display = 'block';
    style.background = 'rgba(255, 153, 0, 0.2)';
  }
  return createElement({
    node: row,
    style,
    stylesheet,
    useInlineStyles,
    key: `code-segement${i}`
  });
});

export default class CodeBlock extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    language: PropTypes.string,
  }

  static defaultProps = {
    language: null,
  }

  render() {
    const { added, updated } = this.props.value.split(/\n/).reduce((tmp, row, i) => {
      if (row.startsWith('+')) {
        tmp.added = tmp.added.concat(i)
      }
      if (row.startsWith('^')) {
        tmp.updated = tmp.updated.concat(i)
      }
      return tmp
    }, { added: [], updated: [] })
    const value = this.props.value.replace(/\n\+/g, '\n').replace(/\n\^/g, '\n')

    return (
      <SyntaxHighlighter
        language={this.props.language}
        style={atomOneDarkReasonable}
        renderer={renderer(added, updated)}
      >
        {value}
      </SyntaxHighlighter>
    );
  }
}